import React, { Component } from 'react';

// require('./Interface.css');

export default class Charts extends Component {
  state = {};
  meta = {}

  componentDidMount = () => {}

  toggle = () => {}

  render = () => {
    return (
      <div className="tile-container" id="charts">
        <div className="tile">CHARTS</div>
      </div>
    )
  }
}