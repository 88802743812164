import React from 'react';

export const daterange = (
  <svg width="837pt" height="853pt" viewBox="0 0 837 853" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d=" M 192.91 0.00 L 226.04 0.00 C 233.74 0.70 241.35 3.63 247.11 8.84 C 253.02 15.06 257.33 23.25 257.15 32.01 C 257.30 73.35 257.15 114.70 257.23 156.04 C 257.21 159.98 257.23 163.96 256.44 167.83 C 254.48 176.39 249.08 184.18 241.49 188.68 C 234.51 193.04 225.99 193.37 218.00 193.22 C 208.63 193.02 199.23 193.61 189.88 192.88 C 179.88 192.12 170.65 186.04 165.47 177.54 C 161.90 171.73 160.87 164.77 160.78 158.06 C 160.80 117.04 160.79 76.01 160.78 34.99 C 160.87 27.82 162.11 20.32 166.38 14.37 C 172.20 5.61 182.57 0.60 192.91 0.00 Z" />
      <path d=" M 609.64 0.00 L 643.63 0.00 C 653.92 0.64 664.28 5.57 669.99 14.38 C 674.43 20.25 675.63 27.83 675.70 35.02 C 675.68 76.33 675.71 117.65 675.69 158.97 C 675.72 166.02 673.77 173.08 670.01 179.05 C 664.43 187.28 654.94 192.66 644.98 193.03 C 635.01 193.47 625.01 193.07 615.03 193.23 C 606.82 193.37 598.16 191.77 591.69 186.38 C 583.46 180.40 579.18 170.05 579.32 160.04 C 579.30 117.69 579.29 75.34 579.33 32.99 C 579.15 26.05 581.35 19.16 585.43 13.55 C 590.78 5.52 600.31 1.21 609.64 0.00 Z" />
      <path d=" M 41.73 84.82 C 49.68 81.39 58.42 80.71 66.97 80.54 C 87.55 80.51 108.14 80.62 128.73 80.49 C 128.66 107.31 128.69 134.14 128.71 160.96 C 128.61 174.29 132.85 187.60 140.70 198.38 C 150.47 212.31 166.15 221.89 182.94 224.50 C 190.58 225.72 198.34 225.19 206.04 225.31 C 214.36 225.19 222.70 225.57 231.02 225.03 C 244.40 224.00 257.13 218.23 267.37 209.68 C 280.89 197.75 289.49 180.14 289.52 162.01 C 289.53 134.83 289.63 107.65 289.46 80.46 C 375.29 80.64 461.12 80.51 546.94 80.53 C 546.95 107.37 546.93 134.20 546.96 161.03 C 546.95 170.62 549.01 180.23 553.28 188.84 C 561.33 205.84 577.30 218.72 595.48 223.35 C 605.44 226.01 615.81 225.17 626.00 225.30 C 638.12 225.13 650.55 226.29 662.29 222.55 C 673.94 218.75 684.81 212.04 692.68 202.55 C 701.99 191.19 707.75 176.76 707.76 161.99 C 707.80 134.84 707.81 107.68 707.75 80.53 C 726.17 80.56 744.58 80.54 762.99 80.54 C 772.82 80.47 782.91 80.57 792.31 83.83 C 815.53 91.07 833.13 112.97 835.97 137.01 C 836.61 142.31 836.43 147.67 836.46 153.00 C 836.45 359.33 836.46 565.65 836.45 771.98 C 836.34 784.21 837.37 796.80 833.27 808.57 C 828.30 822.80 818.54 835.52 805.58 843.37 C 795.90 849.53 784.55 852.29 773.19 853.00 L 65.51 853.00 C 56.56 852.45 47.40 851.49 39.17 847.63 C 17.91 838.67 1.91 817.35 0.54 794.15 L 0.00 798.28 L 0.00 140.22 C 2.07 115.95 18.48 92.91 41.73 84.82 M 59.44 338.69 C 53.10 340.91 48.00 347.05 48.24 353.97 C 48.18 498.65 48.22 643.33 48.22 788.00 C 47.68 796.96 55.98 805.12 64.91 804.54 C 300.29 804.55 535.67 804.54 771.04 804.55 C 780.08 805.18 788.87 797.25 788.30 788.02 C 788.32 643.68 788.30 499.34 788.31 355.00 C 788.84 347.39 783.27 340.07 775.90 338.35 C 772.32 337.70 768.67 337.99 765.06 337.94 C 534.02 337.95 302.98 337.94 71.94 337.94 C 67.78 338.04 63.50 337.53 59.44 338.69 Z" />
      <path d=" M 292.12 461.75 C 294.91 459.55 298.51 457.17 302.20 458.46 C 305.20 460.13 305.32 464.04 305.43 467.08 C 305.49 488.41 305.44 509.74 305.46 531.06 C 380.57 531.04 455.68 531.06 530.79 531.06 C 530.79 510.02 530.78 488.99 530.79 467.96 C 530.73 464.47 531.22 460.40 534.44 458.36 C 538.94 457.14 542.86 460.46 546.07 463.14 C 584.87 495.55 623.74 527.88 662.44 560.41 C 666.28 563.31 669.10 567.99 668.17 572.95 C 667.79 576.51 665.63 579.53 662.92 581.73 C 624.11 613.97 585.33 646.26 546.62 678.62 C 543.22 681.54 539.54 684.96 534.69 684.54 C 531.61 682.32 530.64 678.60 530.80 674.97 C 530.79 653.78 530.75 632.59 530.82 611.39 C 471.89 611.54 412.95 611.41 354.02 611.45 C 337.79 611.38 321.56 611.61 305.34 611.34 C 305.62 632.20 305.36 653.08 305.47 673.95 C 305.29 677.49 305.72 682.28 301.89 684.08 C 298.41 685.39 294.99 682.82 292.40 680.72 C 252.84 647.64 213.18 614.66 173.54 581.67 C 169.40 578.60 167.29 573.15 168.43 568.12 C 169.35 564.58 172.15 562.02 174.83 559.73 C 213.89 527.02 252.98 494.36 292.12 461.75 Z" />
    </g>
  </svg>
)

export const threedot = (
  <svg id="threedot" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32.055 32.055">
    <g>
      <path d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967
        C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967
        s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967
        c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"/>
    </g>
  </svg>
)

export const doublearrow = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 245.035 245.035"  width="512px" height="512px">
    <g>
      <g>
        <g>
          <path d="M27.269,123.841l95.22-84.255l95.22,84.255c6.229,6.229,16.4,6.229,22.597,0     c6.293-6.229,6.293-16.336,0.064-22.597L134.027,7.2c-3.178-3.178-7.342-4.704-11.505-4.64c-4.195-0.064-8.359,1.462-11.537,4.64     L4.672,101.244c-6.229,6.229-6.229,16.368,0,22.597S21.04,130.071,27.269,123.841z" fill="#FFFFFF"/>
          <path d="M134.027,121.172c-3.178-3.178-7.342-4.704-11.505-4.64c-4.195-0.064-8.359,1.462-11.537,4.64     L4.672,215.184c-6.229,6.261-6.229,16.4,0,22.597c6.229,6.261,16.368,6.261,22.597,0l95.22-84.192l95.22,84.192     c6.229,6.261,16.4,6.261,22.597,0c6.229-6.198,6.229-16.336,0-22.597L134.027,121.172z" fill="#FFFFFF"/>
        </g>
      </g>
    </g>
  </svg>
)

export const rotatephone = (
  <svg width="225pt" height="225pt" viewBox="0 0 225 225" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g className="primary">
      <path d=" M 99.84 0.00 L 205.40 0.00 C 215.63 1.10 224.07 9.79 225.00 20.02 L 225.00 205.37 C 223.85 215.54 215.30 224.12 205.05 225.00 L 99.47 225.00 C 92.33 224.04 85.85 219.67 82.46 213.30 C 80.83 209.78 79.87 205.96 80.00 202.06 C 80.03 164.98 80.00 127.90 80.02 90.81 C 80.02 87.21 80.02 83.62 80.02 80.02 C 80.00 62.67 80.02 45.31 80.01 27.96 C 80.02 23.38 79.67 18.63 81.35 14.27 C 84.13 6.47 91.66 0.88 99.84 0.00 M 98.17 11.30 C 93.77 12.84 90.46 17.29 90.76 22.03 C 90.76 82.35 90.75 142.67 90.76 202.98 C 90.41 209.05 95.92 214.68 102.05 214.21 C 135.69 214.26 169.34 214.26 202.99 214.21 C 209.11 214.61 214.60 209.03 214.24 202.94 C 214.29 142.65 214.30 82.35 214.24 22.06 C 214.58 15.98 209.07 10.36 202.95 10.79 C 170.64 10.71 138.33 10.80 106.02 10.74 C 103.40 10.77 100.73 10.63 98.17 11.30 Z" />
      <path d=" M 38.90 27.05 C 36.34 23.95 38.86 18.48 42.97 18.71 C 48.66 19.07 54.31 20.04 59.97 20.76 C 62.93 20.90 65.35 23.95 64.83 26.87 C 63.97 32.58 63.46 38.35 62.49 44.04 C 61.56 48.17 55.50 49.15 53.13 45.75 C 51.49 43.71 52.15 41.04 52.33 38.66 C 43.41 45.21 35.47 52.97 28.37 61.42 C 24.43 66.12 16.68 60.03 20.05 55.04 C 27.31 45.23 36.73 37.31 46.12 29.64 C 43.61 29.21 40.46 29.51 38.90 27.05 Z" />
      <path d=" M 126.32 19.43 C 142.53 19.05 158.76 19.41 174.98 19.25 C 177.54 19.23 180.85 19.12 182.27 21.74 C 184.63 24.94 182.03 29.97 178.09 29.98 C 161.40 30.05 144.71 29.96 128.02 30.02 C 125.07 30.41 121.91 28.05 121.88 25.00 C 121.63 22.38 123.64 19.67 126.32 19.43 Z" />
      <path d=" M 148.48 167.61 C 159.26 164.76 171.01 173.85 171.08 184.99 C 171.78 195.03 162.99 204.40 152.92 204.34 C 143.64 204.83 135.01 197.26 134.05 188.07 C 132.67 178.87 139.38 169.44 148.48 167.61 M 149.38 178.49 C 144.17 180.53 142.91 188.23 147.31 191.73 C 151.63 195.92 159.68 192.90 160.32 186.95 C 161.50 181.08 154.78 175.91 149.38 178.49 Z" />
    </g>
    <g className="secondary">
      <path d=" M 7.45 85.43 C 11.63 81.58 17.37 79.85 22.99 79.99 C 42.00 80.04 61.01 80.00 80.02 80.02 C 80.02 83.62 80.02 87.21 80.02 90.81 C 60.67 90.83 41.32 90.78 21.98 90.83 C 18.02 90.60 14.21 92.81 12.22 96.19 C 10.69 98.50 10.79 101.38 10.73 104.04 C 10.83 137.02 10.71 170.00 10.79 202.98 C 10.41 209.05 15.89 214.60 21.98 214.17 C 42.09 214.21 62.20 214.18 82.31 214.19 L 82.46 213.30 C 85.85 219.67 92.33 224.04 99.47 225.00 L 17.58 225.00 C 13.54 223.49 9.35 221.84 6.36 218.60 C 3.08 215.43 1.28 211.13 0.00 206.84 L 0.00 97.56 C 1.63 93.08 3.65 88.51 7.45 85.43 Z" />
      <path d=" M 22.25 122.37 C 25.49 120.66 29.94 123.22 29.96 126.89 C 30.03 143.94 30.02 161.00 29.97 178.05 C 30.09 182.02 24.96 184.57 21.77 182.34 C 19.51 181.06 19.21 178.28 19.26 175.96 C 19.28 159.96 19.26 143.97 19.27 127.97 C 19.10 125.73 20.18 123.37 22.25 122.37 Z" />
    </g>
  </svg>
)

export const angle = (
  <svg width="532pt" height="532pt" viewBox="0 0 532 532" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g>
    <path d=" M 20.53 157.47 C 31.07 150.40 44.66 147.94 56.97 151.12 C 63.64 152.56 69.51 156.21 75.14 159.90 C 138.76 200.52 202.37 241.14 265.99 281.75 C 331.04 240.21 396.10 198.69 461.16 157.15 C 465.97 153.99 471.36 151.70 477.04 150.68 C 493.47 147.13 511.49 153.85 521.88 167.00 C 527.99 174.56 531.46 184.03 532.00 193.72 L 532.00 196.99 C 531.49 209.60 525.81 221.99 516.15 230.19 C 510.80 234.69 504.64 238.05 498.84 241.91 C 429.51 286.23 360.18 330.58 290.85 374.91 C 276.23 384.61 255.76 384.65 241.15 374.91 C 168.14 328.22 95.13 281.54 22.13 234.85 C 8.90 227.24 0.54 212.36 0.00 197.21 L 0.00 192.39 C 1.30 178.55 8.64 164.97 20.53 157.47 Z" />
    </g>
  </svg>
)

export const hamburger = (
  <svg width="512pt" height="512pt" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d=" M 239.35 9.43 C 274.65 8.70 310.03 10.37 345.05 14.90 C 365.95 17.98 387.03 21.92 406.42 30.62 C 426.98 39.82 444.73 54.71 458.33 72.58 C 471.78 90.23 481.42 110.65 487.27 132.03 C 498.85 179.03 501.54 227.72 500.56 275.97 C 499.83 303.41 497.12 330.84 491.80 357.78 C 488.62 374.39 484.75 391.05 477.35 406.35 C 463.41 436.90 440.01 463.93 409.35 478.45 C 389.47 488.01 367.50 491.91 345.87 495.21 C 297.39 501.60 248.24 502.80 199.48 499.21 C 172.34 497.00 145.02 494.04 118.90 486.04 C 95.99 479.06 75.03 465.85 58.98 448.05 C 38.37 425.45 24.37 396.93 18.63 366.91 C 11.03 331.83 7.98 295.86 7.75 260.00 C 7.03 220.81 10.20 181.45 18.57 143.12 C 24.72 113.24 38.56 84.78 59.13 62.18 C 73.64 46.23 92.08 33.91 112.37 26.63 C 135.49 18.72 159.83 15.35 184.03 12.77 C 202.42 10.98 220.88 9.83 239.35 9.43 M 227.17 42.18 C 200.23 43.37 173.25 45.58 146.77 50.83 C 132.87 53.66 118.87 57.37 106.59 64.71 C 83.70 78.31 66.98 100.70 57.28 125.23 C 51.77 137.89 49.51 151.61 46.88 165.07 C 41.44 195.72 39.58 226.90 39.94 258.00 C 39.71 296.02 43.23 334.23 52.50 371.16 C 58.45 391.38 68.48 410.56 82.72 426.19 C 94.93 439.52 110.52 449.99 127.99 454.97 C 145.33 460.01 163.24 462.80 181.16 464.83 C 231.96 470.42 283.39 470.19 334.13 464.05 C 353.15 461.45 372.43 458.61 390.35 451.44 C 411.54 442.71 428.71 425.98 440.50 406.57 C 448.75 392.79 455.16 377.74 458.18 361.92 C 468.50 316.73 469.55 270.07 467.95 223.94 C 466.54 195.04 462.77 166.20 455.62 138.14 C 446.82 108.68 428.85 80.89 402.09 64.87 C 389.03 56.96 374.01 53.33 359.19 50.39 C 347.93 48.22 336.56 46.61 325.16 45.40 C 292.63 42.03 259.85 40.71 227.17 42.18 Z" />
      <path d=" M 150.50 147.67 C 152.28 147.11 154.15 146.96 156.00 146.92 C 221.33 146.93 286.65 146.95 351.98 146.92 C 358.92 146.76 365.83 151.08 368.35 157.63 C 371.31 164.63 369.20 173.47 363.08 178.10 C 359.97 180.59 355.98 181.82 352.01 181.76 C 288.67 181.79 225.33 181.75 161.99 181.78 C 158.29 181.73 154.48 182.10 150.86 181.06 C 143.85 179.07 138.46 172.37 138.25 165.05 C 137.72 157.34 143.11 149.83 150.50 147.67 Z" />
      <path d=" M 153.43 236.70 C 219.61 236.33 285.82 236.65 352.02 236.54 C 361.94 235.86 370.64 245.35 369.62 255.12 C 369.31 264.11 361.05 271.69 352.09 271.36 C 286.72 271.36 221.36 271.38 156.00 271.35 C 147.60 271.76 139.60 265.18 138.47 256.84 C 136.63 247.46 143.85 237.54 153.43 236.70 Z" />
      <path d=" M 150.31 329.48 C 153.42 328.49 156.72 328.71 159.94 328.70 C 224.28 328.70 288.63 328.70 352.97 328.69 C 357.93 328.78 362.79 331.11 365.96 334.93 C 371.39 341.45 370.86 351.98 365.00 358.06 C 361.74 361.60 356.96 363.64 352.14 363.52 C 289.10 363.52 226.06 363.53 163.01 363.52 C 157.55 363.50 151.64 364.20 146.77 361.14 C 139.34 357.04 136.24 347.11 139.52 339.38 C 141.44 334.72 145.50 330.99 150.31 329.48 Z" />
    </g>
  </svg>
)

export const search = (
  <svg xmlns="http://www.w3.org/2000/svg" height="136pt" version="1.1" viewBox="-1 0 136 136.21852" width="136pt">
    <g>
      <path d="M 93.148438 80.832031 C 109.5 57.742188 104.03125 25.769531 80.941406 9.421875 C 57.851562 -6.925781 25.878906 -1.460938 9.53125 21.632812 C -6.816406 44.722656 -1.351562 76.691406 21.742188 93.039062 C 38.222656 104.707031 60.011719 105.605469 77.394531 95.339844 L 115.164062 132.882812 C 119.242188 137.175781 126.027344 137.347656 130.320312 133.269531 C 134.613281 129.195312 134.785156 122.410156 130.710938 118.117188 C 130.582031 117.980469 130.457031 117.855469 130.320312 117.726562 Z M 51.308594 84.332031 C 33.0625 84.335938 18.269531 69.554688 18.257812 51.308594 C 18.253906 33.0625 33.035156 18.269531 51.285156 18.261719 C 69.507812 18.253906 84.292969 33.011719 84.328125 51.234375 C 84.359375 69.484375 69.585938 84.300781 51.332031 84.332031 C 51.324219 84.332031 51.320312 84.332031 51.308594 84.332031 Z M 51.308594 84.332031 " />
    </g>
  </svg>
)

export const thinsearch = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.005 512.005">
    <g>
      <g>
        <path d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667
          S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6
          c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z
           M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"/>
      </g>
    </g>
  </svg>
)

export const arrow = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="123.964px" height="123.964px" viewBox="0 0 123.964 123.964">
    <g>
      <path d="M121.7,57.681L83,26.881c-4-3.1-10-0.3-10,4.8v10.3c0,3.3-2.2,6.2-5.5,6.2H6c-3.3,0-6,2.4-6,5.8v16.2c0,3.2,2.7,6,6,6h61.5
        c3.3,0,5.5,2.601,5.5,5.9v10.3c0,5,6,7.8,9.9,4.7l38.6-30C124.7,64.781,124.8,60.081,121.7,57.681z"/>
    </g>
  </svg>
)

export const history = (
  <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m276 138v109.716l72.809 72.809c7.811 7.811 7.811 20.474 0 28.284-7.811 7.811-20.474 7.81-28.284 0l-78.667-78.667c-3.751-3.75-5.858-8.838-5.858-14.142v-118c0-11.046 8.954-20 20-20s20 8.954 20 20zm58.667-138h-157.334c-41.89 0-83.217 14.722-116.284 43.478v-23.478c0-11.046-8.954-20-20-20s-20 8.954-20 20v78.667c0 11.046 9.031 20 20 20h78.667c11.046 0 20-8.954 20-20s-8.954-20-20-20h-37.833c25.314-24.405 59.485-38.667 95.45-38.667h157.333c75.727 0 137.334 61.607 137.334 137.333v157.333c0 75.727-61.607 137.334-137.333 137.334h-157.334c-75.726 0-137.333-61.607-137.333-137.333v-78.667c0-11.046-8.954-20-20-20s-20 8.954-20 20v78.667c0 97.782 79.551 177.333 177.333 177.333h157.333c97.783 0 177.334-79.551 177.334-177.333v-157.334c0-97.782-79.551-177.333-177.333-177.333z"/>
    </g>
  </svg>
)